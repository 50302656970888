<template>
  <b-card
    no-body
    class="mb-0 border-0"
  >
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <div class="reward-section">
        <div class="total-rewards-savings-text">
          Total Savings: <span class="color-blue"> {{ totalSavings }} </span>
        </div>
        <referrals-invite />
      </div>
      <b-table
        v-if="isLoaded"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="savingsList"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No savings found"
      >
        <template #cell(orderSeqId)="data">
          <b-link
            class="color-blue d-inline-block text-nowrap"
            :to="{ name: 'order/detail', params: { orderId: data.item._id } }"
          >
            {{ data.item.orderSeqId }}
          </b-link>
        </template>
        <template #cell(retailerName)="data">
          <span class="text-capitalize">{{ data.item.retailerName }}</span>
        </template>
        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ClockIcon"
              size="18"
              class="mr-50 text-info"
            />
            <span class="align-text-top">{{ getDateFromTimestamp(data.item.createdAt) }}</span>
          </div>
        </template>
      </b-table>
      <div
        v-if="totalSavingsList"
        class="mx-2 mb-2 mt-auto"
      >
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="elements-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSavingsList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BCard,
  BSpinner,
  BLink,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { getDateFromTimestamp } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { formatObject } from '@/@core/utils/utils'
import { GET_SAVINGS } from '@/store/modules/wallet.module'
import { GET_SAVINGS_TABLE_COLUMNS } from '@/table-columns-constants'
import ReferralsInvite from '../referral/ReferralsInvite.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BCard,
    BSpinner,
    FeatherIcon,
    ReferralsInvite,
    BLink,
},
  data() {
    return {
      isLoaded: true,
      savingsList: [],
      currentPage: 1,
      perPage: 10,
      totalSavingsList: 0,
      tableColumns: GET_SAVINGS_TABLE_COLUMNS(),
      totalSavings: 0,
      getDateFromTimestamp,
    }
  },
  created() {
    this.loadSavings()
  },
  methods: {
    loadSavings() {
      this.isLoaded = false
      this.$store.dispatch(GET_SAVINGS).then(res => {
        const result = res.data.data
        this.savingsList = result.data
        this.totalSavingsList = this.savingsList.length
        this.totalSavings = result.totalSavings
        this.isLoaded = true
        analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_SAVINGS, formatObject({ savingsCount: this.totalSavingsList, totalSavings: this.totalSavings }))
      }).catch(err => {
        apiToastError(err)
        this.isLoaded = true
      })
      },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>
